import {createSvgIcon} from '../../../icons/create-svg-icon';

export const SharedFolderFileIcon = createSvgIcon(
  <>
    <path
      d="m24 392a32 32 0 0 0 32 32h320a32 32 0 0 0 32-32v-224a32 32 0 0 0 -32-32h-176v-64a32 32 0 0 0 -32-32h-112a32 32 0 0 0 -32 32z"
      fill="#fbe36a"
    />
    <path
      d="m296 472h192c0-57.4-22.395-90.958-54.793-114.089a56 56 0 1 0 -82.414 0c-32.393 23.131-54.793 56.689-54.793 114.089z"
      fill="#6ed2f0"
    />
  </>
);
