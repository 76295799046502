import {createSvgIcon} from '../../../icons/create-svg-icon';

export const ArchiveFileIcon = createSvgIcon(
  <g>
    <g id="Vrstva_x0020_1_31_">
      <path
        clipRule="evenodd"
        d="m202.669 0h264.71l224.322 233.811v454.727c0 56.689-46.227 102.916-102.916 102.916h-386.116c-56.932 0-102.916-46.227-102.916-102.916v-585.622c0-56.932 45.984-102.916 102.916-102.916z"
        fill="#ffb11f"
        fillRule="evenodd"
      />
      <g fill="#fff">
        <path
          clipRule="evenodd"
          d="m467.136 0v231.865h224.565z"
          fillRule="evenodd"
          opacity=".302"
        />
        <path d="m317.749 411.177v-54.013c0-5.839-4.623-10.462-10.462-10.462h-29.196c-5.839 0-10.462 4.623-10.462 10.462v54.013l-16.301 47.687c-4.623 13.381-2.433 28.223 5.839 39.658 8.029 11.435 21.41 18.247 35.522 18.247s27.25-6.812 35.522-18.247 10.462-26.276 5.839-39.658zm-6.812 74.936c-8.272 11.922-28.223 11.922-36.738 0-4.136-5.839-5.353-13.625-2.92-20.437l5.353-16.058h31.872l5.596 16.058c2.19 6.812 1.216 14.598-3.163 20.437zm-82.965-429.424v29.683c0 5.839 4.623 10.462 10.462 10.462h39.658v44.281h-39.658c-5.839 0-10.462 4.623-10.462 10.462v22.87c0 5.839 4.623 10.705 10.462 10.705h39.658v44.037h-39.658c-5.839 0-10.462 4.623-10.462 10.462v23.113c0 5.839 4.623 10.462 10.462 10.462h39.658v33.575c0 5.839 4.866 10.462 10.705 10.462h58.149c5.596 0 10.462-4.623 10.462-10.462v-23.113c0-5.596-4.866-10.462-10.462-10.462h-39.901v-44.037h39.901c5.596 0 10.462-4.623 10.462-10.462v-23.113c0-5.839-4.866-10.462-10.462-10.462h-39.901v-44.037h39.901c5.596 0 10.462-4.866 10.462-10.705v-22.87c0-5.839-4.866-10.705-10.462-10.705h-39.901v-40.389c-.001 0-79.073.243-79.073.243z" />
      </g>
    </g>
  </g>
);
