import {createSvgIcon} from '../../../icons/create-svg-icon';

export const AudioFileIcon = createSvgIcon(
  <g>
    <g id="Vrstva_x0020_1_12_">
      <path
        clipRule="evenodd"
        d="m202.63 0h264.877l224.149 233.97v454.364c0 56.904-45.927 103.12-102.831 103.12h-386.195c-56.615 0-102.831-46.216-102.831-103.12v-585.503c-.001-56.615 46.216-102.831 102.831-102.831z"
        fill="#008bf5"
        fillRule="evenodd"
      />
      <g fill="#fff">
        <path
          clipRule="evenodd"
          d="m467.218 0v231.948h224.438z"
          fillRule="evenodd"
          opacity=".302"
        />
        <path d="m298.528 405.548c25.13-24.841 59.504-40.15 97.343-40.15s72.213 15.309 97.054 40.15c25.13 24.841 40.439 59.215 40.439 96.765v61.525c0 8.088-3.177 15.598-8.666 20.797-5.199 5.488-12.709 8.666-20.797 8.666h-14.154v-89.833h15.309v-1.155c0-29.752-12.421-56.904-32.063-76.546-19.931-19.931-47.083-32.063-77.123-32.063-30.041 0-57.482 12.132-77.412 32.063-19.642 19.642-31.774 46.794-31.774 76.546v1.155h15.309v89.833h-14.154c-8.377 0-15.598-3.177-21.086-8.666-5.199-5.199-8.666-12.709-8.666-20.797v-61.525c.002-37.55 15.6-71.924 40.441-96.765zm146.16 82.612h7.799c6.066 0 11.554 2.6 15.309 6.355 4.044 4.044 6.355 9.532 6.355 15.598v76.835c0 6.066-2.311 11.554-6.355 15.309-3.755 4.044-9.243 6.644-15.309 6.644h-7.799c-4.044 0-7.51-1.733-10.11-4.333s-4.044-6.066-4.044-10.11v-92.144c0-3.755 1.444-7.51 4.044-9.821 2.599-2.6 6.066-4.333 10.11-4.333zm-105.431 0h7.51c4.044 0 7.51 1.733 10.11 4.333 2.6 2.311 4.044 6.066 4.044 9.821v92.144c0 4.044-1.444 7.51-4.044 10.11s-6.066 4.333-10.11 4.333h-7.51c-6.066 0-11.554-2.6-15.598-6.644-4.044-3.755-6.355-9.243-6.355-15.309v-76.835c0-6.066 2.311-11.554 6.355-15.598 4.044-3.756 9.532-6.355 15.598-6.355z" />
      </g>
    </g>
  </g>
);
