import clsx from 'clsx';
import {DefaultFileIcon} from './icons/default-file-icon';
import {AudioFileIcon} from './icons/audio-file-icon';
import {VideoFileIcon} from './icons/video-file-icon';
import {TextFileIcon} from './icons/text-file-icon';
import {PdfFileIcon} from './icons/pdf-file-icon';
import {ArchiveFileIcon} from './icons/archive-file-icon';
import {FolderFileIcon} from './icons/folder-file-icon';
import {ImageFileIcon} from './icons/image-file-icon';
import {PowerPointFileIcon} from './icons/power-point-file-icon';
import {WordFileIcon} from './icons/word-file-icon';
import {SpreadsheetFileIcon} from './icons/spreadsheet-file-icon';
import {SharedFolderFileIcon} from './icons/shared-folder-file-icon';

interface Props {
  type?: string;
  mime?: string | null;
  className?: string;
}
export function FileTypeIcon({type, mime, className}: Props) {
  if (!type && mime) {
    type = mime.split('/')[0];
  }
  // @ts-ignore
  const Icon = FileTypeIcons[type] || FileTypeIcons.default;
  let Vb = '0 0 791.454 791.454';
  if (type === 'folder') Vb = '0 0 512 512';
  return (
    <Icon className={clsx(className, `${type}-file-color`)} viewBox={Vb} />
  );
}

const FileTypeIcons = {
  default: DefaultFileIcon, // done
  audio: AudioFileIcon, // done
  video: VideoFileIcon, // done
  text: TextFileIcon, // done
  pdf: PdfFileIcon, // done
  archive: ArchiveFileIcon, //done
  folder: FolderFileIcon, //done
  sharedFolder: SharedFolderFileIcon, //done
  image: ImageFileIcon, // done
  powerPoint: PowerPointFileIcon, //done
  word: WordFileIcon, //done
  spreadsheet: SpreadsheetFileIcon, //done
};
