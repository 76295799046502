import {createSvgIcon} from '../../../icons/create-svg-icon';

export const PowerPointFileIcon = createSvgIcon(
  <g>
    <g id="Vrstva_x0020_1_18_">
      <path
        clipRule="evenodd"
        d="m202.808 0h264.609l224.265 233.758v454.661c0 56.956-46.079 103.035-102.838 103.035h-386.036c-56.956 0-103.035-46.079-103.035-103.035v-585.384c-.001-56.956 46.078-103.035 103.035-103.035z"
        fill="#e03303"
        fillRule="evenodd"
      />
      <g fill="#fff">
        <path
          clipRule="evenodd"
          d="m467.219 0v231.978h224.463z"
          fillRule="evenodd"
          opacity=".302"
        />
        <path d="m224.166 566.2v-143.775h61.109c15.03 0 27.094 4.153 36.191 12.657 8.899 8.306 13.448 19.381 13.448 33.422 0 13.844-4.549 25.116-13.448 33.422-9.097 8.306-21.161 12.459-36.191 12.459h-24.523v51.815zm36.587-83.061h20.37c5.537 0 9.69-1.187 12.855-3.758 2.966-2.571 4.351-6.328 4.351-10.877 0-4.746-1.384-8.306-4.351-10.877-3.164-2.571-7.317-3.955-12.855-3.955h-20.37zm89.587 83.061v-143.775h61.109c15.03 0 27.094 4.153 36.191 12.657 9.097 8.306 13.448 19.381 13.448 33.422 0 13.844-4.351 25.116-13.448 33.422s-21.161 12.459-36.191 12.459h-24.325v51.815zm36.784-83.061h20.172c5.537 0 9.888-1.187 12.855-3.758 2.966-2.571 4.549-6.328 4.549-10.877 0-4.746-1.582-8.306-4.549-10.877s-7.317-3.955-12.855-3.955h-20.172zm126.767 83.061v-112.528h-40.937v-31.247h118.857v31.247h-41.135v112.528z" />
      </g>
    </g>
  </g>
);
