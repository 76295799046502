import {createSvgIcon} from '../../../icons/create-svg-icon';

export const TextFileIcon = createSvgIcon(
  <g>
    <g id="Vrstva_x0020_1_25_">
      <path
        clipRule="evenodd"
        d="m202.808 0h264.609l224.265 233.758v454.661c0 56.956-46.079 103.035-102.838 103.035h-386.036c-56.956 0-103.035-46.079-103.035-103.035v-585.384c-.001-56.956 46.078-103.035 103.035-103.035z"
        fill="#251d36"
        fillRule="evenodd"
      />
      <g fill="#fff">
        <path
          clipRule="evenodd"
          d="m467.219 0v231.978h224.463z"
          fillRule="evenodd"
          opacity=".302"
        />
        <path d="m249.282 577.868v-112.528h-41.135v-31.247h118.857v31.247h-40.937v112.528zm211.411 0h-38.564l-26.303-44.102-26.303 44.102h-38.762l45.684-76.733-39.948-67.042h38.564l20.765 34.807 20.568-34.807h38.762l-39.948 67.24zm44.695 0v-112.528h-40.938v-31.247h118.857v31.247h-41.135v112.528z" />
      </g>
    </g>
  </g>
);
