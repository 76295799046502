import {createSvgIcon} from '../../../icons/create-svg-icon';

export const ImageFileIcon = createSvgIcon(
  <g>
    <g id="Vrstva_x0020_1_9_" clipRule="evenodd" fillRule="evenodd">
      <path
        d="m202.8 0h264.71l224.217 233.922v454.459c0 56.891-46.182 103.073-103.073 103.073h-385.854c-56.891 0-103.073-46.182-103.073-103.073v-585.308c0-56.891 46.182-103.073 103.073-103.073z"
        fill="#0ac963"
      />
      <g fill="#fff">
        <path d="m467.176 0v231.914h224.552z" opacity=".302" />
        <path d="m496.29 395.56h-201.126c-15.059 0-27.442 12.382-27.442 27.442v127.837c0 15.059 12.382 27.442 27.442 27.442h201.126c15.059 0 27.107-12.382 27.107-27.442v-127.838c0-15.059-12.047-27.441-27.107-27.441zm-146.243 34.469c16.398 0 29.449 13.386 29.449 29.449 0 16.398-13.051 29.784-29.449 29.784s-29.784-13.386-29.784-29.784c0-16.063 13.386-29.449 29.784-29.449zm155.279 120.81c0 5.02-4.016 9.37-9.036 9.37h-201.126c-5.02 0-9.036-4.35-9.036-9.37v-5.354l36.477-36.477 30.119 30.119c3.681 3.681 9.37 3.681 13.051 0l75.632-75.632 63.919 63.919z" />
      </g>
    </g>
  </g>
);
