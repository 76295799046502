import {createSvgIcon} from '../../../icons/create-svg-icon';

export const DefaultFileIcon = createSvgIcon(
  <g>
    <g id="Vrstva_x0020_1_23_">
      <path
        clipRule="evenodd"
        d="m202.669 0h264.71l224.322 233.811v454.727c0 56.689-46.227 102.916-102.916 102.916h-386.116c-56.932 0-102.916-46.227-102.916-102.916v-585.622c0-56.932 45.984-102.916 102.916-102.916z"
        fill="#251d36"
        fillRule="evenodd"
      />
      <g fill="#fff">
        <path
          clipRule="evenodd"
          d="m467.136 0v231.865h224.565z"
          fillRule="evenodd"
          opacity=".302"
        />
        <path d="m499.981 516.039h-208.508c-9.732 0-17.518 7.786-17.518 17.518 0 9.489 7.786 17.274 17.518 17.274h208.508c9.732 0 17.518-7.786 17.518-17.274 0-9.733-7.786-17.518-17.518-17.518zm0 69.584h-208.508c-9.732 0-17.518 7.786-17.518 17.518 0 9.489 7.786 17.274 17.518 17.274h208.508c9.732 0 17.518-7.786 17.518-17.274 0-9.733-7.786-17.518-17.518-17.518zm-106.079-208.508h-102.429c-9.732 0-17.518 7.542-17.518 17.274s7.786 17.274 17.518 17.274h102.429c9.732 0 17.518-7.542 17.518-17.274s-7.786-17.274-17.518-17.274zm106.079 69.34h-208.508c-9.732 0-17.518 7.786-17.518 17.518 0 9.489 7.786 17.274 17.518 17.274h208.508c9.732 0 17.518-7.786 17.518-17.274 0-9.732-7.786-17.518-17.518-17.518z" />
      </g>
    </g>
  </g>
);
